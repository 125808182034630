import React from "react";
import Modal from "react-modal";
import BlankLink from "../BlankLink";
import { TYPE_DOCKLE, TYPE_TRIVY } from "../../constants";
import DockleTable from "./DockleTable";
export default props => {
  if (props.fetching) {
    return (
      <Modal
        isOpen
        shouldCloseOnOverlayClick
        onRequestClose={props.handleClose}
        contentLabel="Downloading"
      >
        <h2>
          Fetching JSON data...
          <BlankLink href={props.link}>Open in New Tab</BlankLink>
          <button onClick={props.handleClose}>close</button>
        </h2>
      </Modal>
    );
  }

  if (
    props.type === TYPE_DOCKLE &&
    props.json &&
    props.json.data &&
    props.json.data.details
  ) {
    return (
      <Modal
        isOpen
        shouldCloseOnOverlayClick
        onRequestClose={props.handleClose}
        contentLabel="Dockle"
      >
        <h2>
          <BlankLink href={props.link}>JSON Detail</BlankLink>
          <button onClick={props.handleClose}>close</button>
        </h2>
        <DockleTable json={props.json.data.details} />
      </Modal>
    );
  }
  if (
    props.type === TYPE_TRIVY &&
    props.json &&
    props.json.data &&
    props.json.data.detail
  ) {
    return (
      <Modal
        isOpen
        shouldCloseOnOverlayClick
        onRequestClose={props.handleClose}
        contentLabel="Trivy"
      >
        <h2>
          <BlankLink href={props.link}>JSON Detail</BlankLink>
          <button onClick={props.handleClose}>close</button>
        </h2>
        <DockleTable json={props.json.data.detail} />
      </Modal>
    );
  }

  return null;
};
