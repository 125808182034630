import React from "react";
import Table from "./components/Table";
import SocialButtons from "./components/SocialButtons";
import Logo from "./components/Logo";
import ShareButtons from "./components/ShareButtons";

function App() {
  return (
    <div>
      <Logo />
      <SocialButtons />
      <Table />
      <ShareButtons />
    </div>
  );
}

export default App;
