import styled from 'styled-components';

export default styled.span`
  && {
    border: 2px solid ${p => p.color};
    color: ${p => p.color};
    padding: 0.25em .5em .2em;
    margin: 0px .2em;
    border-radius: 5px;
  }
`;
