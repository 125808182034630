import React from 'react';
import column from './column'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import CircledNumber from '../CircledNumber'
import Modal from '../Modal'
import styled from 'styled-components';
import axios from 'axios'
import {TYPE_DOCKLE, TYPE_TRIVY} from "../../constants";

const getJsonUrl = (imageName, file) => {
    const dirName = imageName.replace("/", ":")
    return `/jsons/${dirName}/${file}`;
}

const NumberRenderer = (props) => (<div>{props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>)
const ImageRenderer = (props) => {
    const imageUrl = props.value.split(";")
    return (<a href={imageUrl[1]} target="_blank" rel="noopener noreferrer">{imageUrl[0]}</a>)
}

const DockleRenderer = (props) => {
    return (<div>
        <CircledNumber color="red">{props.value.fatal}</CircledNumber>
        <CircledNumber color="#ff7326">{props.value.warn}</CircledNumber>
        <CircledNumber color="blue">{props.value.info}</CircledNumber>
    </div>)
}

const TrivyRenderer = (props) => (
    props.value ? (
    <div>
        <CircledNumber color="#C30939">{props.value.CRITICAL}</CircledNumber>
        <CircledNumber color="red">{props.value.HIGH}</CircledNumber>
        <CircledNumber color="#ff7326">{props.value.MEDIUM}</CircledNumber>
        <CircledNumber color="blue">{props.value.LOW}</CircledNumber>
        <CircledNumber color="black">{props.value.UNKNOWN}</CircledNumber>
    </div>) : <div>-</div>
)

const StyledDiv = styled.div`&& {height: calc(100vh - 320px)}`

const frameworkComponents = {
    comma: NumberRenderer,
    image:ImageRenderer,
    dockle: DockleRenderer,
    trivy: TrivyRenderer,
}

export default class Table extends React.Component {
    state = {
        dialogType : null,
        image: null,
        json : null,
    }

    handleClick = (cell) => {
        if (cell.colDef.field === TYPE_DOCKLE ||cell.colDef.field === TYPE_TRIVY) {
            this.setState({dialogType: cell.colDef.field, json: null, image:cell.data.image, fetching: true})
            axios.get(getJsonUrl(cell.data.image.name, `${cell.colDef.field}.json`))
                .then((response) => {
                    this.setState({json:response, fetching:false})
                })
                .catch((error) => {
                    // handle error
                    console.log(error);
                    this.setState({dialogType: null, fetching:false})
                });
        }
    }

    handleCloseDialog = () => {
        this.setState({dialogType:null, json:null, image: null, fetching: false})
    }

    render() {
        const {dialogType, json, image, fetching} = this.state;
        const targetLink = image ? getJsonUrl(image.name, `${dialogType}.json`) : null;
        return (
            <StyledDiv className="ag-theme-balham">
                <Modal fetching={fetching} type={dialogType} json={json} link={targetLink} handleClose={this.handleCloseDialog} />
                <AgGridReact
                    defaultColDef={{
                        filter: true,
                        sortable: true,
                        resizable: true,
                    }}
                    frameworkComponents={frameworkComponents}
                    floatingFilter
                    suppressRowClickSelection
                    columnDefs={column.columnDefs}
                    onCellClicked={this.handleClick}
                    rowData={column.rowData}/>
            </StyledDiv>
        )
    }
}

