import React from "react";
import BlankLink from "./BlankLink"
import styled from 'styled-components';

export default () => (
    <div>

        <StyledDiv>
            <BlankLink href="https://github.com/goodwithtech/dockle"><img src="/imgs/dockle-small.png" height="48px" alt="dockle" /></BlankLink>

            <Iframe
        src="https://ghbtns.com/github-btn.html?user=goodwithtech&repo=dockle&type=star&count=true&size=large"
        title="star dockle" frameBorder="0" scrolling="0" width="160px" height="30px"
    />
    <BlankLink
        href="https://twitter.com/tomoyamachi?ref_src=twsrc%5Etfw"
        className="twitter-follow-button"
        data-show-count="false"
    >
        @tomoyamachi
    </BlankLink>

    <Iframe src="https://ghbtns.com/github-btn.html?user=tomoyamachi&type=follow&size=large" frameBorder="0" scrolling="0"
            title="follow @tomoyamachi" width="230px" height="30px"></Iframe>

            </StyledDiv>
        <StyledDiv>
            <BlankLink href="https://github.com/aquasecurity/trivy"><img src="/imgs/trivy-small.png" height="48px" alt="trivy" /></BlankLink>
            <Iframe
                src="https://ghbtns.com/github-btn.html?user=aquasecurity&repo=trivy&type=star&count=true&size=large"
                title="star dockle" frameBorder="0" scrolling="0" width="160px" height="30px"
            />
            <BlankLink
                href="https://twitter.com/knqyf263?ref_src=twsrc%5Etfw"
                className="twitter-follow-button"
                data-show-count="false"
            >
                @knqyf263
            </BlankLink>

            <Iframe src="https://ghbtns.com/github-btn.html?user=knqyf263&type=follow&size=large" frameBorder="0" scrolling="0"
                    title="follow @knqyf263" width="230px" height="30px"></Iframe>
        </StyledDiv>
    </div>
)


const Iframe = styled.iframe`&&{

padding:0 0 0 20px;
}`
const StyledDiv = styled.div`&& {
display: flex; 
flex-direction:row;
flex-wrap:wrap;
justify-content: left;
align-items: center;
height: 48px;
padding:5px;
}`
