import React from "react";
import BlankLink from "./BlankLink"

export default () => (
    <div>
    <h1>
        Security Issues in Popular Containers
    </h1>
        This page collect popular container's vulnerabilities on <BlankLink href="https://hub.docker.com/" target="_blank" >Docker Hub</BlankLink>.<br/>
        These containers scanned with <BlankLink href="https://github.com/goodwithtech/dockle">Dockle</BlankLink> and <BlankLink href="https://github.com/knqyf263/trivy">Trivy</BlankLink>. This page created by <BlankLink href="https://twitter.com/tomoyamachi">tomoyamachi</BlankLink>@<BlankLink href="https://twitter.com/goodwith_tech">GoodWithTech</BlankLink>.
    </div>
)
