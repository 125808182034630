import vulns from "./vulns.json";

const scoreLadder = [9.5, 8, 5.5, 3, 1];

const getScore = result => {
  let score = 0;
  result.forEach((count, key) => (score += count * scoreLadder[key]));
  return Math.floor(score);
};

const getVulns = vulnList => {
  return vulnList.map(vuln => {
    const dockle = { ...vuln.dockle };
    delete dockle.pass;
    const dockleSum = Object.values(dockle).reduce((a, b) => a + b, 0);
    let trivySum = 0;
    let score = getScore(Object.values(dockle));

    if (vuln.trivy) {
      trivySum = Object.values(vuln.trivy).reduce((a, b) => a + b, 0);
      score += getScore(Object.values(vuln.trivy));
    }

    const imageNameUrl = `${vuln.image.name};${vuln.image.url}`;
    return { ...vuln, dockleSum, trivySum, score, imageNameUrl };
  });
};

export default {
  columnDefs: [
    {
      headerName: "Image",
      field: "imageNameUrl",
      width: 300,
      cellRenderer: "image"
    },
    {
      headerName: "Tag",
      field: "tag",
      width: 100,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "OS",
      field: "osFamily",
      width: 80,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Ver",
      field: "osVersion",
      width: 60,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Pulls",
      field: "pulls",
      width: 100,
      cellRenderer: "comma",
      type: "numericColumn",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Last Updated",
      field: "last_updated",
      width: 100,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Score",
      field: "score",
      width: 100,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      sort: "desc"
    },
    {
      headerName: "Dockle",
      field: "dockle",
      width: 100,
      cellRenderer: "dockle",
      filter: false,
      sortable: false
    },
    {
      headerName: "Trivy",
      field: "trivy",
      width: 250,
      cellRenderer: "trivy",
      filter: false,
      sortable: false
    },
    {
      headerName: "Star",
      field: "star",
      width: 70,
      cellRenderer: "comma",
      type: "numericColumn",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Image Bytes",
      field: "size",
      width: 100,
      cellRenderer: "comma",
      type: "numericColumn",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Dockle Total",
      field: "dockleSum",
      width: 100,
      type: "numericColumn",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Trivy Total",
      field: "trivySum",
      width: 100,
      type: "numericColumn",
      filter: "agNumberColumnFilter"
    }
  ],
  rowData: getVulns(vulns)
};
