import React from "react";
import _ from "./sharebutton.css";

export default () => (
  <div>
    <a
      class="resp-sharing-button__link"
      href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcontainers.goodwith.tech%2F"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
        <div
          aria-hidden="true"
          class="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
          </svg>
        </div>
      </div>
    </a>

    <a
      class="resp-sharing-button__link"
      href="https://twitter.com/intent/tweet/?text=Security%20Issues%20of%20Popular%20Containers&amp;url=https%3A%2F%2Fcontainers.goodwith.tech%2F"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
        <div
          aria-hidden="true"
          class="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
          </svg>
        </div>
      </div>
    </a>

    <a
      class="resp-sharing-button__link"
      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fcontainers.goodwith.tech%2F&amp;title=Security%20Issues%20of%20Popular%20Containers&amp;summary=Security%20Issues%20of%20Popular%20Containers&amp;source=https%3A%2F%2Fcontainers.goodwith.tech%2F"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small">
        <div
          aria-hidden="true"
          class="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
          </svg>
        </div>
      </div>
    </a>

    <a
      class="resp-sharing-button__link"
      href="https://reddit.com/submit/?url=https%3A%2F%2Fcontainers.goodwith.tech%2F&amp;resubmit=true&amp;title=Security%20Issues%20of%20Popular%20Containers"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--small">
        <div
          aria-hidden="true"
          class="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
          </svg>
        </div>
      </div>
    </a>

    <a
      class="resp-sharing-button__link"
      href="https://news.ycombinator.com/submitlink?u=https%3A%2F%2Fcontainers.goodwith.tech%2F&amp;t=Security%20Issues%20of%20Popular%20Containers"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <div class="resp-sharing-button resp-sharing-button--hackernews resp-sharing-button--small">
        <div
          aria-hidden="true"
          class="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
            <path
              fill-rule="evenodd"
              d="M60.94 82.314L17 0h20.08l25.85 52.093c.397.927.86 1.888 1.39 2.883.53.994.995 2.02 1.393 3.08.265.4.463.764.596 1.095.13.334.262.63.395.898.662 1.325 1.26 2.618 1.79 3.877.53 1.26.993 2.42 1.39 3.48 1.06-2.254 2.22-4.673 3.48-7.258 1.26-2.585 2.552-5.27 3.877-8.052L103.49 0h18.69L77.84 83.308v53.087h-16.9v-54.08z"
            />
          </svg>
        </div>
      </div>
    </a>

    <span>
      Copyright ©<a href="https://twitter.com/goodwith_tech">GoodWithTech</a>
    </span>
  </div>
);
